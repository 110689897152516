import * as React from 'react'
import { StaticImage } from 'gatsby-plugin-image'

import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

import Layout from '@components/layout'
import MustRead from '@components/must-read'

const LandApplicationPage = () => {
  return (
    <Layout pageTitle="Land Application">
      <Container fluid="xxl">
        <Row>
          <Col className="no-gutters">
            <div className="hero-banner large-hero">
              <StaticImage
                className="hero-image"
                src="../../../media/land_application_hero.jpg"
                alt=""
                aspectRatio={3.75 / 1}
                layout="fullWidth"
              />
              <div className="hero-fade"></div>
              <div className="hero-text">
                <h2>Services</h2>
                <h1>Land Application</h1>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <main>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <h3>
                Since 2001 our focus has been on agricultrual beneficial use programs
              </h3>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl" className="section text-center">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <p>
                More and more agricultural operations are turning to the use of biosolids and other soil amendments in place of commercial fertilizers. Biosolids provide essential nutrients such as nitrogen and phosphorus for healthier plant growth but they also contain micro nutrients that enhance the growth process and help increase crop yields. They also add organic matter, increase moisture retention, and reduce soil erosion. Biosolids continue to be safe to use as demontrated by scientific evidence by government scientists, health experts, and agronomists.
              </p>
            </Col>
            <Col>
              <p>
                Understanding that our soils are an essential component to a sustainable future, Wessuc is dedicated to the beneficial use of organic residuals in an environmentally responsible manner. Wessuc handles over 400,000 m3 of organic waste per year for various municipal and industrial clients. Land application is done with the latest in spreading equipment, using tractors with a continuously variable transmission for accurate speed control and application rates.
              </p>
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section default-text">
          <Row xl={2} lg={2} md={2} sm={1} xs={1}>
            <Col>
              <h3>MANURE APPLICATION</h3>
              <p>
                Planning, mixing, pumping, haulage and spreading services are available to help you get the most out of you manure nutrient resource. We have extensive experience with nutrient management plans and will provide all required documentation including GPS spreading data for your records. Field operators are OMAFRA trained and licensed to ensure nutrients are placed at the right time, right place, and right amount. Environmentally sensitive features of each field will be reviewed prior to application to ensure appropriate protection measures are in place. Our operators can move up to 1 million imperial gallons per day depending on the field location, material consistency, and site characteristics.
              </p>
            </Col>
            <Col>
              <StaticImage 
                src="../../../media/land_application_manure_application.jpg"
                alt="a tractor top spreads biosolids in a large field" />
            </Col>
          </Row>
        </Container>
        <Container fluid="xxl"><hr/></Container>
        <Container fluid="xxl" className="section text-center">
          <Row>
            <Col>
              <iframe
                id="land-application-video"
                src="https://www.youtube.com/embed/6HGcFD6kbNU" 
                title="YouTube video player" 
                frameborder="0" 
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                allowfullscreen></iframe>
            </Col>
          </Row>
        </Container>
      </main>
      <MustRead />
    </Layout>
  )
}

export default LandApplicationPage